const DELAY_IN_MILLISECONDS = 6000;
const ANIMATE_DELAY_IN_MILLISECONDS = 2048;

class MrSlideshow extends HTMLElement {

	// MARK: properties.
	#currentIndex = 0;

	#startTime = 6000;

	#remainingTime = 6000;

	#ticker: number | null = null;

	#animating: boolean | null = false;

	#tick = (): void => {
		if ( this.paused ) {
			return;
		}

		this.#remainingTime = DELAY_IN_MILLISECONDS;

		this.goToNextItem();
	};

	// MARK: lifecycle.
	connectedCallback() {
		if ( this.paused ) {
			this.play();
		}
	}

	disconnectedCallback() {
		if ( !this.paused ) {
			this.pause();
		}

		// Reset States.
		this.#currentIndex = 0;
		this.#ticker = null;
		this.#animating = false;

		this.#startTime = DELAY_IN_MILLISECONDS;
		this.#remainingTime = DELAY_IN_MILLISECONDS;
	}

	// MARK: methods.
	private goToNextItem() {
		if ( this.#animating ) {
			return;
		}

		const items = this.querySelectorAll( '[data-slide-item]' );
		const newIndex = indexPlusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.transition( newIndex );
	}

	private transition( index: number ) {
		if ( !this.paused ) {
			// Clear and restart the Ticker if the carousel is playing.
			if ( null !== this.#ticker ) {
				window.clearTimeout( this.#ticker );
			}

			this.#startTime = Date.now();

			this.#ticker = window.setTimeout( this.#tick, this.#remainingTime );
		}

		this.#animating = true;

		const currentItem = this.querySelector( '[data-slide-item-current]' );
		const nextItem = this.querySelector( '[data-slide-item-next]' );

		if ( !currentItem || !nextItem ) {
			return;
		}

		currentItem.setAttribute( 'data-is-animating', '' );

		// Fallback in case the transition events don't work.
		const delayPromise = new Promise( ( resolve ) => {
			setTimeout(
				resolve,
				ANIMATE_DELAY_IN_MILLISECONDS + 50 // Pad with 50ms, this should leave enough time for the animations to run.
			);
		} );

		// Wait for transition to finish.
		const transitionDonePromise = new Promise<void>( ( resolve ) => {
			const carouselTransitionEnd = ( e: TransitionEvent ) => {
				// Check if target exist and is instance of HTMLElement.
				if ( !e.target || !( e.target instanceof HTMLElement ) ) {
					return;
				}

				// check if target has attribute data-slide-item.
				if ( !e.target.hasAttribute( 'data-slide-item' ) ) {
					return;
				}

				this.removeEventListener( 'transitionend', carouselTransitionEnd );

				resolve();
			};

			this.addEventListener( 'transitionend', carouselTransitionEnd );
		} );

		// Start the race.
		Promise.race( [
			delayPromise,
			transitionDonePromise,
		] ).then( () => {
			// Render to current index.
			this.updateStateAfterTransition( index );
			// Re-enable transitions.
			this.#animating = false;
		} );
	}

	private updateStateAfterTransition( index: number ) {
		const items = this.querySelectorAll( '[data-slide-item]' );
		const length = items.length;

		// Check if items not available.
		if ( 2 > length ) {
			return;
		}

		// Get current, previous and next items.
		const current = items[index];
		const next = items[indexPlusOne( index, length, true )];

		// Set attributes.
		// First reset all items.
		items.forEach( ( item ) => {
			item.removeAttribute( 'data-slide-item-current' );
			item.removeAttribute( 'data-slide-item-next' );
			item.removeAttribute( 'data-is-animating' );
		} );

		current.setAttribute( 'data-slide-item-current', '' );
		next.setAttribute( 'data-slide-item-next', '' );
	}

	get paused(): boolean {
		return !this.hasAttribute( 'data-slide-playing' );
	}

	play() {
		return new Promise<void>( ( resolve ) => {
			requestAnimationFrame( () => {
				if ( !this.paused ) {
					// Already playing.
					resolve();

					return;
				}

				this.#startTime = Date.now();

				// Set playing to true.
				this.setAttribute( 'data-slide-playing', '' );
				this.#ticker = window.setTimeout( this.#tick, this.#remainingTime );

				const itemsContainer = this.querySelector( '[data-slide-items]' );
				if ( itemsContainer ) {
					itemsContainer.setAttribute( 'aria-live', 'off' );
				}

				resolve();
			} );
		} );
	}

	pause() {
		// Always clear the timer, even when already paused.
		if ( null !== this.#ticker ) {
			window.clearTimeout( this.#ticker );
		}

		if ( this.paused ) {
			// Already paused.
			return Promise.resolve();
		}

		this.#remainingTime -= Date.now() - this.#startTime;
		this.#ticker = null;

		// Set playing to false.
		this.removeAttribute( 'data-slide-playing' );

		const itemsContainer = this.querySelector( '[data-slide-items]' );
		if ( itemsContainer ) {
			itemsContainer.setAttribute( 'aria-live', 'polite' );
		}

		return Promise.resolve();
	}
}

customElements.define( 'mr-slideshow', MrSlideshow );

function indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
