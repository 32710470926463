import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';

import { PrivacyControls, initMenuButtons } from '@mrhenry/wp--mr-interactive';

/* Modules */
import './modules/input-sink';
import './modules/mr-accordion';
import './modules/mr-video';
import './modules/mr-video-overlay';
import './modules/navigation-overlay';
import './modules/play-pause-toggle';
import './modules/mr-slideshow';

PrivacyControls.setupPrivacyAwareEmbedders();

requestAnimationFrame( () => {
	initMenuButtons();
} );
